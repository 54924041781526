import { CardComponent } from '../ReusableComponents/CardComponent/CardComponent' // Adjust the path as needed
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { PAYMENT_METHOD_DOMAIN } from './PaymentMethodConstants'
import { TableComponent } from '../ReusableComponents/TableComponent/TableComponent'
import { PaymentIcons } from '../ReusableComponents/PaymentIcons/PaymentIcons'
import { TableColumn } from '../ReusableComponents/TableComponent/TableComponentTypes'
import { ConfigurationComponent } from '../ConfigurationComponent/ConfigurationComponent'

export const PaymentMethodComponent = () => {
  //remove this flag when we want payment Domain Enabled
  const paymentDomainEnabled = false
  //TODO move all mock data to getConfig API hook
  const domainData = [
    {
      domain: 'https://www.monicascoffeeshop.com',
      paymentMethods: ['Apple Pay']
    },
    {
      domain: 'https://www.monicascoffeeshop123.com',
      paymentMethods: ['Apple Pay']
    }
  ]

  //TODO move this to table data file after API integration
  const domainColumns: TableColumn[] = [
    { heading: 'Domain', dataKey: 'domain' },
    {
      heading: 'Enabled payment methods',
      dataKey: 'paymentMethods',
      render: (paymentMethods: string[] | undefined) => (
        <div className='payment-icons-container'>
          {paymentMethods?.map((paymentType, index) => (
            <PaymentIcons key={index} paymentType={paymentType} />
          ))}
        </div>
      )
    }
  ]

  return (
    <div className='px-40 flex flex-col space-y-8 bg-gray-0 pb-8'>
      <h1 className='type-headline-3 font-bold font-normal mt-8'>
        Payment methods
      </h1>
      <DividingLine />

      <div className='flex flex-col space-y-8'>
        <ConfigurationComponent />

        {paymentDomainEnabled && (
          <CardComponent
            heading={PAYMENT_METHOD_DOMAIN.HEADING}
            subheading={PAYMENT_METHOD_DOMAIN.SUBHEADING}
            buttonText={PAYMENT_METHOD_DOMAIN.BUTTON_TEXT}
          >
            <TableComponent
              columns={domainColumns}
              data={domainData}
              showModalButton={true}
            />
          </CardComponent>
        )}
      </div>
    </div>
  )
}
