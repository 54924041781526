export const PAYMENT_METHOD_CONFIG = {
  HEADING: 'Payment method configurations',
  SUBHEADING:
    'Add and manage your configurations where you want to enable payment methods.',
  BUTTON_TEXT: 'Add a configuration',
  ADD: 'Add configuration',
  EDIT: 'Edit configuration',
  NAME_LABEL: 'Configuration name',
  NAME_PACEHOLDER: 'Enter a configuration name',
  PAYMENT_METHOD_EMPTY_MESSAGE: 'Select atleast one payment method',
  CONFIG_NAME_EMPTY_MESSAGE: 'Please enter a configuration name'
}

export const PAYMENT_METHOD_DOMAIN = {
  HEADING: 'Payment method domains',
  SUBHEADING:
    'Add and manage your domains where you want to enable payment methods.',
  BUTTON_TEXT: 'Add a new domain'
}
